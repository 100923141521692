@tailwind base;
@tailwind components;
@tailwind utilities;

/* base için özelleştirme yapmak isterseniz buraya yazabilirsiniz. */
@layer base {
  html, body, #root {
    width: 100%;
    height: 100%;
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
}

.gradient-text {
  background: -webkit-linear-gradient(45deg, #00C0FD, #E70FAA 80%);
  -webkit-background-clip: text;
  /* apply background clip */
  -webkit-text-fill-color: transparent;
}